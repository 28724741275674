@import "./src/common/general.scss";
.l-navigation-wrapper {
  position: relative;
  z-index: 17;
  border-bottom: solid $colorGrey--200;
  border-bottom-width: $borderWidthReduced;
  background-color: $colorWhite;

  @media #{$lteLayout} {
    padding: 0;
  }
}

.c-navigation {
  position: relative;
  display: flex;

  @media #{$gtLayout} {
    margin-left: -$headingHorizontalPadding;
    margin-right: -$headingHorizontalPadding;
  }
}

.c-backlayer {
  @include fade-in-animation(0.2s);
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: rgba(0 0 0 / 60%);
  z-index: 16;
}

@keyframes fade-in {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@mixin fade-in-animation($time) {
  animation: fade-in $time;
}

@import "./src/common/general.scss";
.e-heading-link__discount-percentage  {
    display: inline-block;
    max-width: 100%;
    text-align: center;
    align-items: center;
    border-radius: 50%;
    padding: 0 0.3rem;
    margin-right: 0.4rem;
    line-height: 1.4;

    &--yellow {
      background-color: $colorYellow--300;
    }

    &--red {
      background-color: $colorRed--400;
      color: $colorWhite;
    }
  }
@import "./src/common/general.scss";
$headingPaddingTop: 0.8rem;
$headingPaddingBottom: 0.625rem;

.c-heading-item {
  &--hidden {
    visibility: hidden;
  }

  &--show-more {
    @include fade-in-animation(0.2s);
    margin-left: 0.5em;
  }
}

.e-heading-link {
  position: relative;
  display: flex;
  text-decoration: none;
  white-space: nowrap;
  padding: $headingPaddingTop $headingHorizontalPadding $headingPaddingBottom $headingHorizontalPadding;
  transition: background-color 0.2s;
  border-radius: 0.2em 0.2em 0 0;

  > svg {
    fill: currentColor;
  }

  &::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: $borderWidth;

    background-color: $colorBlack;
    border-radius: 20px;

    .c-heading-item--active & {
      content: '';
    }
  }

  &:focus::after {
    content: '';
  }

  @media #{$gtLine} {
    margin: 0 0.5rem;
  }

  @media #{$gtLayout} {
    margin: unset;

    &:hover {
      background-color: $colorGrey--100;
    }
  }
}

.e-heading-link-arrow {
  display: none;

  @media #{$gtLayout} {
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5em;
    @include size(1rem);
    margin: 0.06rem 0.5rem 0.06rem 0rem;
    transition: transform 0.2s;
  }

  .c-heading-item--active & {
    transform: rotate(-180deg);
  }
}

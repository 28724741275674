@import "./src/common/general.scss";
.e-leaf {
  line-height: 2rem;
  @media #{$gtLayout} {
    line-height: 1.75rem;
  }
}

.e-leaf__link {
  color: $colorGrey--600;
  font-weight: $fontWeightRegular;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%;
  overflow: hidden;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

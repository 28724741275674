@import "./src/common/general.scss";
.c-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% + 1px);
  background-color: $colorWhite;
  border-radius: 0 0 10px 10px;
  box-shadow: $shadow;
  opacity: 0;
  display: none;
  z-index: 1000;
  max-height: unset;
  overflow-y: visible;

  &--visible {
    @include fade-in-animation(0.2s);
    opacity: 1;
    display: grid;
  }

  @media #{$gtLayout} {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    margin: 0;
  }
}

.c-dropdown__content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($columnWidth, 1fr));
  gap: 3.125rem 2rem;
  background-color: $colorWhite;
  padding: 0 1rem;

  @media #{$gtLayout} {
    padding: 2.5rem 3.5rem;
  }

  @media #{$lteColumn} {
    grid-template-columns: 1fr;
  }
}

.e-dropdown-closing-cross {
  display: inline-block;
  padding: 0.6rem 1.2rem;

  @media #{$gtLayout} {
    display: none;
  }
}

.e-dropdown-closing-button {
  margin: 1.6rem 0;
  display: block;

  @media #{$gtLayout} {
    display: none;
  }
}

@import "./src/common/general.scss";
@import '../Heading/Heading.scss';

$showMoreWidth: 120px;

.c-headline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: hidden;
  
  @media #{$gtLayout} {
    gap: 0.5em;
    flex-wrap: wrap;
    height: $lineHeightReduced + $headingPaddingBottom + $headingPaddingTop;
    overflow: hidden;
    max-width: calc(100% - $showMoreWidth);
  }

  &--swipeable {
    overflow-x: auto;
  }

  @media #{$lteLayout} {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@import "./src/common/general.scss";
.e-horizontal-scroll-indicator {
  display: flex;
  position: absolute;
  z-index: 1;
  justify-content: flex-start;
  align-content: center;
  width: 7.13rem;
  height: 100%;
  pointer-events: none;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #{$colorWhite} 78.65%);

  &--right {
    justify-content: flex-end;
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #{$colorWhite} 78.65%);
  }

  @media #{$gtLayout} {
    display: none;
  }
}

.e-horizontal-scroll-indicator__button {
  pointer-events: all;
  padding: 0 1rem;
}

.e-horizontal-scroll-indicator__arrow {
  margin: auto 0;
}
